import { Link } from "react-router-dom";

// Component to render individual links and sub-links in a grid layout
const LinkComponent = ({
  link,
  currentColor,
  activeLink,
  normalLink,
  handleActive,
}) => (
  <li className="w-full">
    <Link
      to={`/${link.url}`} // Navigates to the URL
      onClick={handleActive} // Closes sidebar on link click
      //   style={({ isActive }) => ({
      //     backgroundColor: isActive ? currentColor : "", // Active link background color
      //   })}
      //   className={({ isActive }) => (isActive ? activeLink : normalLink)} // Applies active/normal styles
    >
      <span className="capitalize text-lg md:text-2xl font-semibold text-black hover:text-gray-900 transition duration-200">
        {/* Adjust text size and color */}
        {link.name}
      </span>
    </Link>

    {/* Sub-links rendering */}
    {link.subMenuLinks && (
      <ul className="grid grid-cols-1 sm:grid-cols-2 ml-6">
        {link.subMenuLinks.map((subLink) => (
          <li key={subLink.name} className="w-full py-1">
            <Link
              to={`/${subLink.url}`}
              onClick={handleActive}
              //   style={({ isActive }) => ({
              //     backgroundColor: isActive ? currentColor : "",
              //   })}
              //   className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              <span className="capitalize text-md md:text-lg font-semibold text-black hover:text-gray-900 transition duration-200 ">
                {/* Responsive padding added */}
                {subLink.name}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    )}
  </li>
);

// Main component to map and display user links with a responsive grid layout
const UserLinksComponent = ({
  userLinks,
  currentColor,
  activeLink,
  normalLink,
  handleActive, // Function to handle active link state
}) => {
  return (
    <div className="w-full">
      {" "}
      {/* Responsive grid layout */}
      {/* Map through all user links and display them */}
      {userLinks.map((item) => (
        <div key={item.title} className="p-1">
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {" "}
            {/* Nested grid layout for links */}
            {item.links.map((link) => (
              <LinkComponent
                key={link.name}
                link={link}
                handleActive={handleActive}
                currentColor={currentColor}
                activeLink={activeLink}
                normalLink={normalLink}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default UserLinksComponent;
